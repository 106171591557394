/* eslint-disable no-unused-vars */
import axios from "axios";
import Cookie from "universal-cookie";
import { showNotification } from '@mantine/notifications';
import { getURL } from "../connection";
import {queryClient} from "../App";
const cookies = new Cookie();

let headers = {Authorization:`Bearer ${getToken()}`};
const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
let url = getURL();
function getToken(){
    return cookies.get("TOKEN")
};

function getUser(){
    return cookies.get("USER");
};

export function resetQueries(){
    queryClient.invalidateQueries();
};
export function isLogin(){
    if(cookies.get("TOKEN")!== "") return true;
    return false
};

async function createOneMember(values) {
    let data = values
    data.monthOfBirth = months.indexOf(data.months)+1;
    data.timeStamp = Date.now();
    const res = await axios({
        headers:headers,
        method: "post",
        url: url + "member",
        data: data
    }).then(e=>showNotification({title:"Succès",message:`Le membre ${data.prenom} ${data.nom} a été ajouté.`}))
    .catch(e => showNotification({title:"Erreur",message:`Une erreur c'est produite ${e}`,color:"red"}))
    return res;
};
export async function createOneEvent(f) {
    let data = f.values;
    data.dateStart = new Date(data.date.getFullYear(), data.date.getMonth(), data.date.getDate(), data.time[0].getHours(), data.time[0].getMinutes());
    data.dateEnd = new Date(data.date.getFullYear(), data.date.getMonth(), data.date.getDate(), data.time[1].getHours(), data.time[1].getMinutes());
    data.timeStamp = Date.now();
    const res = await axios({
        headers:headers,
        method: "post",
        url: url + "event",
        data: data
    }).then((e) => showNotification({title:"Succès",message:`L'événement ${data.title} a été ajouté`}))
        .catch((e) => showNotification({title:"Erreur",message:`${e}`,color:"red"}));
    return res;
};
export async function createOneWorker(f) {
    let data = f.values;
    data.timeStamp = Date.now();
    const res = await axios({
        headers:headers,
        method: "post",
        url: url + "worker/register",
        data: data
    }).then()
        .catch();
    return res;
};
export async function createOneWorkerFromMember(memberID){

    const res = await axios({
        headers:headers,
        method:"post",
        url:url+"worker/volunteer",
        data: {memberID,password:generatePassword()}
    }).then()
        .catch()
    return res;

}
export async function patchWorker(f){
    let data = f.values;
    const res = axios({
        headers:headers,
        method:"post",
        url:`${url}worker/edit`,
        data:data
    }).then((values) => { showNotification({title:"Succès",message:`L'utilisateur est modifié(e)`});resetQueries()})
    .catch(e => {showNotification({title:"ERREUR",message:e,color:"red"})});
    return res;
}
export async function patchMember(f) {
    const data = f.values;
    const res = axios({
        headers:headers,
        method: "post",
        url: `${url}member/edit`,
        data: data
    }).then(resetQueries())
        .catch();
    return res;
}
export async function fetchMemberByID({ queryKey }) {
    const [_index, membre] = queryKey;
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}member/${membre}`,
        params: { membre: membre },
    });
    return res.data;
};
export async function fetchMemberSameAddress({ queryKey }) {
    const [_index, membre] = queryKey;
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}member/sameAdd/${membre}`,
        params: { membre: membre },
    });
    return res.data;
};
export async function fetchAllMembers() {
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}member`,
    });
    return res.data;
};
export async function exportAllMembers(){
    const res = await axios({
        headers:headers,
        method:"post",
        url: `${url}member/excel`,
        data:getUser
    });
    return res.data;
}
export async function searchMember({ queryKey }) {
    let [_, search] = queryKey;
    if (search === "") search = "a";
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}member/search/${search}`
    })
    return res.data;
};
export async function fetchAllStats() {
    const res = await axios({
        headers:headers,
        method: "get",
        url: url + "stats/dashboard"
    });
    return res.data;
}
export async function fetchWorker({ queryKey }) {
    const [_, worker] = queryKey;
    if(!worker) return "NO WORKER"
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}worker/${worker}`,
        params: { worker: worker,_ }
    });
    return res.data;
};
export async function fetchAllWorkers() {
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}worker`,
    });
    return res.data;
};


//EVENTS
export async function fetchAllEvents() {
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}event/upcoming`,
    });
    return res.data;
};
export async function fetchEventByID({ queryKey }) {
    const [_, event] = queryKey
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}event/${event}`,
        params: { event: event,_ }
    });
    return res.data;
}
export async function deleteEventByID(id) {
    const res = await axios({
        headers:headers,
        method: "delete",
        url: `${url}event/${id}`
    });
    return res.data;
}
export async function fetchParticipantEmail(members){
    const id = members._id
    const res = await axios({
        headers:headers,
        method:"get",
        url: `${url}member/email/${id}`,
    })
    window.open("mailto:"+res.data.email)
    return res;
}
export async function editEventByID(f) {
    const data = f.values;
    const res = axios({
        method: "post",
        url: `${url}event/update`,
        data: data
    }).then((response) => showNotification({ title: 'Evenement Modifié !', message: `L'évenement ${f.values.title} est bien modifié` }))
        .catch((e) => showNotification({ title: 'Erreur !', message: `Une erreur s'est produite`, color: "red" }));
    return res;
}
export async function addMemberToEvent(f,eventID){
    const data = f.values;
    const res = axios({
        method:"put",
        headers:headers,
        url:`${url}event/addMember/${eventID}`,
        data:data
    }).then((response)=> showNotification({title:"Événement Modifié!",message:"Les membres ont été ajoutés"}))
        .catch((e)=> showNotification({title:"Erreur !",message:"Une erreur s'est produite",color:"red"}))
    return res;
}
export async function pullMemberFromEvent(memberID,eventID){
    let data = {member:memberID,event:eventID}
    const res = axios({
        method:"put",
        headers:headers,
        url:`${url}event/pullMember/${eventID}`,
        data:data,
    }).then((response)=> showNotification({title:"Événement Modifié!",message:"Les membres ont été retirés"}))
        .catch((e)=> showNotification({title:"Erreur !",message:"Une erreur s'est produite",color:"red"}))
    return res;
}
export async function deleteWorkerByID(worker){
    const res = await axios({
        headers:headers,
        method:"delete",
        url:`${url}worker/${worker}`,
        params:{worker:worker}
    });
    return res.data;
}
export async function deleteMemberByID(id) {
    const res = await axios({
        headers:headers,
        method: "delete",
        url: `${url}member/${id}`
    });
    queryClient.invalidateQueries({ queryKey: ['members'] })
    return res.data
}
//CHRONO
export async function createOneChrono(post) {
    let { f, c,worker } = post;
    f.validate();
    if (!f.isValid()) return "ERROR";
    let data = f.values;
    data.worker = worker._id;
    data.description = c;
    data.timeStamp = Date.now();
    const res = await axios({
        headers:headers,
        method: "post",
        url: `${url}chrono`,
        data: data
    }).then((response) => resetQueries())
        .catch((e) => `ERROR : ${e}`);
    return res;
}
export async function fetchChronosByMemberID(querykey){
    const[_,memberId] = querykey.queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}chrono/member/${memberId}`
    });
    return res.data;
}
export async function fetchAllActiveChronos(){
    const res = await axios({
        headers:headers,
        method:'get',
        url:`${url}chrono/actif`
    });
    return res.data;
}
export async function fetchAllMemberlessChronos(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}chrono/memberless`
    });
    return res.data;
}
export async function fetchChronoByID({queryKey}){
    const [_,chronoId]=queryKey;
    console.log('QUERY',queryKey);
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}chrono/${chronoId}`
    });
    return res.data;
}
export async function postFollowUp(props) {
    const { id, followUps, newFollowUp } = props
    let newFollowUps = followUps;
    (followUps === undefined) ? newFollowUps = [{ description: newFollowUp, timeStamp: new Date(Date.now()).toISOString(), author: getUser() }] : newFollowUps.push({ description: newFollowUp, timeStamp: new Date(Date.now()).toISOString(), author: getUser() });
    const res = await axios({ headers:headers,method: "post", url: `${url}chrono/followUp`, data: { id: id, followUp: newFollowUps } })
    return res;
}
export async function closeChrono(id) {
    const res = await axios({ headers:headers,method: "post", url: `${url}chrono/close`, data: { id: id } });
    return res;
}
export async function inProgChrono(id) {
    const res = await axios({ headers:headers,method: "post", url: `${url}chrono/prog`, data: { id: id } });
    return res;
}
export async function patchChrono(chrono){
    console.log(chrono);
    const res = await axios({
        headers:headers,
        method:"put",
        url:`${url}chrono/${chrono._id}`,
        "data":chrono
    })
    return res

}
//PAYMENTS
export async function createOnePayment(document){
    const res = await axios({
        headers:headers,
        method:"post",
        url:`${url}payment`,
        data:document
    }).then((response) => {showNotification({title:"Succès",message:"Paiement ajouté"});resetQueries()})
    .catch((e) => {showNotification({title:"ERREUR",message:e,color:"red"})});
    return res;
};
export async function fetchPaymentsFromMonth({queryKey}){
    const [_,monthID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}payment/getMonth/${monthID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};
export async function fetchPaymentsBetween({queryKey}){
    const [_,dates] = queryKey;
    const res = await axios({
        headers:headers,
        method:"post",
        url:`${url}payment/getBetween`,
        data:{startingDate:dates[0],endingDate:dates[1]}
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;

};
export async function fetchAllPayments(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}payment`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};
export async function fetchPaymentByID({queryKey}){
    const [_,paymentID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}payment/${paymentID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})})
    console.log(res)
    return res.data;
}
export async function deletePayment(paymentID){
    const res = await axios({
        headers:headers,
        method:"delete",
        url:`${url}payment/${paymentID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};
export async function cancelPayment(paymentID,cancelReason){
    const res = await axios({
        headers:headers,
        method:"put",
        url:`${url}payment/cancel/${paymentID}`,
        data:cancelReason
    }).then((e)=>{showNotification({title:"Modification Réussie",message:"Le paiement a été annulé"})})
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
};
export async function receivePayment(paymentID){
    const res = await axios({
        headers:headers,
        method:"put",
        url:`${url}payment/receive/${paymentID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
};
export async function fetchPaymentsByMemberID({queryKey}){
    const [_index, memberID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}payment/member/${memberID}`
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};
export async function modifyPayment(paymentID,document){
    const res = await axios({
        headers:headers,
        method:"put",
        url:`${url}payment/${paymentID}`,
        data:document
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
export async function exportAllPayments(){
    const res = await axios({
        headers:headers,
        method:"post",
        url: `${url}payment/excel`,
        data:getUser
    });
    return res.data;
}
//GRANT
export async function fetchAllGrants(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}grant`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})})
    return res.data;
}
export async function fetchGrantByID({queryKey}){
    const [_,grantID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}grant/${grantID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})})
    console.log(res)
    return res.data;
}
export async function createOneGrant(payload){
    const res = await axios({
        headers:headers,
        method:"post",
        url:`${url}grant`,
        data:payload
    }).then((response) => {showNotification({title:"Succès",message:"Subvention ajouté"});resetQueries()})
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})})
    return res;
}
//JOBS
export async function fetchJobByID({queryKey}){
    const [_,id] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}job/${id}`
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
export async function fetchJobsDayByWorkerID({queryKey}){
    const [_,workerID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}job/day/${workerID}`
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
export async function fetchAllJobs(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}job/`
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
export async function fetchJobsByWorkerID({queryKey}){
    const [_,workerID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}job/worker/${workerID}`
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
export async function fetchWorkflowByWorkerID({queryKey}){
    const [_,workerID]= queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}job/workflow/${workerID}`
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
export async function createJobDay(document){
    const res = await axios({
        headers:headers,
        method:"post",
        url:`${url}job/day`,
        data:document
    }).then((response) => {showNotification({title:"Succès",message:"Course ajoutée"});resetQueries()})
    .catch((e) => {showNotification({title:"ERREUR",message:e,color:"red"})});
    return res;
}
export async function createOneJob(document){
    const res = await axios({
        headers:headers,
        method:"post",
        url:`${url}job`,
        data:document
    }).then((response) => {showNotification({title:"Succès",message:"Course ajoutée"});resetQueries()})
    .catch((e) => {showNotification({title:"ERREUR",message:e,color:"red"})});
    return res;
}
export async function modifyJobDay(hours,jobID){
    const res = await axios({
        headers:headers,
        method:"patch",
        url:`${url}job/day/${jobID}`,
        data:{hours}
    }).then((response) => {showNotification({title:"Succès",message:"Course ajoutée"});resetQueries()})
    .catch((e) => {showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}

export async function modifyJobByID(document){
    const res = await axios({
        headers:headers,
        method:"patch",
        url:`${url}job/${document.id}`,
        data:document
    }).then((response)=> {showNotification({title:"Succès",message:"Information modifiée"})})
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
}
export async function deleteJobByID(jobID){
    const res = await axios({
        headers:headers,
        method:"delete",
        url:`${url}job/${jobID}`,
    }).then((response)=> {showNotification({title:"Succès",message:"Information modifiée"})})
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
}
export async function fetchVolunteerStatsByWorker({queryKey}){
    const [_,__,workerID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}job/workstat/${workerID}`,
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
//RUNS
export async function createOneRun(document){
    const res = await axios({
        headers:headers,
        method:"post",
        url:`${url}run`,
        data:document.values
    }).then((response) => {showNotification({title:"Succès",message:"Course ajoutée"});resetQueries()})
    .catch((e) => {showNotification({title:"ERREUR",message:e,color:"red"})});
    return res;
};
export async function fetchRunsFromMonth({queryKey}){
    const [_,monthID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}run/getMonth/${monthID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};
export async function fetchRunsBetween({queryKey}){
    const [_,dates] = queryKey;
    const res = await axios({
        headers:headers,
        method:"post",
        url:`${url}run/getBetween`,
        data:{startingDate:dates[0],endingDate:dates[1]}
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;

};
export async function fetchAllRuns(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}run`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};

export async function deleteRun(runID){
    const res = await axios({
        headers:headers,
        method:"delete",
        url:`${url}run/${runID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};
export async function cancelRun(runID,cancelReason){
    const res = await axios({
        headers:headers,
        method:"put",
        url:`${url}run/cancel/${runID}`,
        data:cancelReason
    }).then((e)=>{showNotification({title:"Modification Réussie",message:"Le paiement a été annulé"})})
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
};
export async function receiveRun(runID){
    const res = await axios({
        headers:headers,
        method:"put",
        url:`${url}run/receive/${runID}`
    }).then()
    .catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
};
export async function fetchRunsByMemberID({queryKey}){
    const [_index, memberID] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}run/member/${memberID}`
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
};
export async function modifyRun(runID,document){
    const res = await axios({
        headers:headers,
        method:"put",
        url:`${url}run/${runID}`,
        data:document
    }).then().catch((e)=>{showNotification({title:"ERREUR",message:e,color:"red"})});
    return res.data;
}
export async function exportAllRuns(){
    const res = await axios({
        headers:headers,
        method:"post",
        url: `${url}run/excel`,
        data:getUser
    });
    return res.data;
}
//STATS
export async function getDemography() {
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}stats/demography`
    });
    return res.data;
}
export async function getChronotivity() {
    const res = await axios({
        headers:headers,
        method: "get",
        url: `${url}stats/chronotivity`
    });
    return res.data;
}
export async function getGender(){
    const res = await axios({
        headers:headers,
        method: `get`,
        url:`${url}stats/gender`
    });
    return res.data;
}
export async function getAge(){
    const res = await axios({
        headers:headers,
        method: `get`,
        url:`${url}stats/age`
    });
    return res.data;
}
//BUTLER
export async function fetchAlerts(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}butler/alert`
    }).then().catch((e)=> {showNotification({title:"Erreur",color:"red"})})
    return res.data;
};
export async function fetchToDo(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}butler/todo`
    }).then().catch((e)=> {showNotification({title:"Erreur",color:"red"})})
    return res.data;
};
export async function fetchAllByWorker({queryKey}){
    let [_,flow,workerId] = queryKey;
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}butler/flow/${workerId}`
    }).then().catch((e)=> {showNotification({title:"Erreur",color:"red",message:"Erreur durant l'acquisition des données"})});
    return res.data;
}
export async function fetchAllVolunteers(){
    const res = await axios({
        headers:headers,
        method:"get",
        url:`${url}worker/volunteers`
    }).then()
    .catch((e)=> {showNotification({title:"Erreur",color:"red",message:e.message})})
    return res.data
}
//AUTH
export async function logIn(values,cb){
    const response = await axios({
        method:"post",
        url:url+"login",
        data:values})
        .catch((e) => {showNotification({title:"Erreur "+e.response.status,message: e.response.status === 401 ? "Mot de passe incorrect":e.response.message,color:"red"})})
        .then(e =>{  
            if(e.status < 400){
            cookies.set("TOKEN",e.data.token,{path:"/"});
            cookies.set("USER",e.data.id,{path:"/"});
            headers = {Authorization:`Bearer ${getToken()}`};
            cb("/auth")
            
        }
        return true
        });

};
export async function changePassword(values){
    const response = await axios({
        headers:headers,
        method:"post",
        url:url+"worker/changePassword",
        data:values
    }).catch(e=> showNotification({title:"Erreur",message:e.message,color:"red"}))
        .then(r => {
            if(response.status === 200){
                showNotification({title:"Succès",message:"le mot de passe a été changé avec succès"});
            }
            if(response.status === 401){
                showNotification({title:"Erreur",message:"Mot de passe erroné",color:"red"});
            }
        })
}
export async function updatePassword(values){
    const response = await axios({
        headers:headers,
        method:"post",
        url:url+"worker/updatePassword",
        data:values
    }).catch(e=> showNotification({title:"Erreur",message:e.message,color:"red"}))
        .then(r => {
            if(response.status === 200){
                showNotification({title:"Succès",message:"le mot de passe a été changé avec succès"});
            }
            if(response.status === 401){
                showNotification({title:"Erreur",message:"Mot de passe erroné",color:"red"});
            }
        })
}
export async function changeProfilePicture(payload){
    const res = axios.post()
}

function generatePassword() {
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789!@#$%^&*+';
    let password = '';
    for (let i = 0; i < 12; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters[randomIndex];
    }
    return password;
}

export {
    getToken,
    getUser,
    createOneMember,
}
