import { useState, React,useEffect } from 'react';
import {
    createStyles,
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Chip,
    Center,
    Flex,
    Pagination,
    Paper,
    Stack
} from '@mantine/core';
import { keys } from '@mantine/utils';
import { Link } from 'react-router-dom';
import { IconSelector, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { IconCalendarPlus } from '@tabler/icons-react';
import PaymentRow from './components/PaymentRow';

const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },

    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },
    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));

function Th({ children, reversed, sorted, onSort, justify = "apart" }) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position={justify}>
                    <Text weight={500} size="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}

function filterData(data, search, filterButton) {
    const filter = filterButton;
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => { if (typeof item[key] == "string") return item[key].toLowerCase().includes(query); return null;  })
    ).filter((item) => {

        if(filter.includes("nan") && item.refund === 0) return true;
        if (filter.includes("waiting") && item.refunded === false && item.refund !== 0) return true;
        if(filter.includes("refunded") && item.refunded === true && item.refund !== 0) return true;
        return false
    });
}

function sortData(data,payload) {
    const { sortBy } = payload;
    if (!sortBy) {

        return filterData(data, payload.search, payload.filterButton);
    }
    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }
            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search, payload.filterButton
    );
}

export function WorkStatTable({data}) {
    const {classes,theme} = useStyles();
    let search = "";
    const [activePage, setPage] = useState(1);
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [filterButton, setFilterButton] = useState(["refunded", "waiting","nan"]);
    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search, filterButton }));
    };
    const setFilter = (event) => {
        setFilterButton(event);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search, filterButton: event }));
        console.dir(sortedData);
    };
    useEffect(()=>{
        setSortedData(data)
    },[data])

    const rows = data ? sortedData.slice((activePage-1)*12,(activePage)*12).map((row) => (
        <PaymentRow key={row?._id} row={row}/>

    )):<div>Choisir un mois</div>;

    function Header(){
        return (<Flex gap={"lx"}>
                    <UnstyledButton component={Link} to={"/auth/form/addJob"} className={classes.item}>
                        <Stack gap={0} align='center'>
                                <IconCalendarPlus color={theme.colors["violet"][6]} size={32} />
                                <Text weight={"bolder"} size="xs" color={"violet"}>Ajouter un bénévolat</Text>
                        </Stack>
                    </UnstyledButton>
                </Flex>)
            }
    return (
        <>  
        <Paper p={'lg'}>
            <Header/>
            <Flex>
    <Chip.Group multiple value={filterButton} onChange={setFilter}>
            <Chip value='waiting' color='red'>En attente de paiement</Chip>
            <Chip value='refunded' color='green'>Remboursés</Chip>
            <Chip value="nan">Aucun remboursement</Chip>
    </Chip.Group>
</Flex>
            <ScrollArea>
                <Table
                    horizontalSpacing="xs"
                    verticalSpacing="xs"
                    sx={{  minWidth: 900 }}>
                    <thead>
                        <tr>
                            <th></th>
                            <Th
                                sorted={sortBy === 'member'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('member')}
                            >
                                Utilisateur
                            </Th>
                            <Th
                                sorted={sortBy === 'distance'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('distance')}
                            >
                                Distance
                            </Th>
                            <Th
                                sorted={sortBy === 'time'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('time')}
                            >
                                Heures
                            </Th>
                            <Th
                                sorted={sortBy === 'refund'}
                                reversed={reverseSortDirection}
                                onSort={() => setSorting('refund')}
                            >
                                Remboursement
                            </Th>
                            <th>
                                Mois
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <Text weight={500} align="center">
                                        Vide
                                    </Text>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Center>
                <Pagination page={activePage} onChange={setPage} total={(data.length/12+1)} />
                </Center>
            </ScrollArea>
        </Paper>
        </>
    );
}
