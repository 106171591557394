import React from 'react'
import { Card, Table, Flex, Text, Title } from '@mantine/core'
import { useQuery } from 'react-query'
import MonthBadge from '../job/components/MonthBadge'
import WorkerCard from './components/WorkerCard'
import { fetchJobByID } from '../connections/requests'

/*
worker
time
volunteer
description
timeStamp
month
*/
export default function JobMonthCard({id}) {

    const jobQuery = useQuery(["job",id],fetchJobByID);
 console.log(id)
    if(!id) return (<Card><Text>ERREUR</Text></Card>)

    if(jobQuery.status === "success") return (
                <Card>
                    <Card.Section>
                        <Flex align={"center"}>
                    <WorkerCard worker={jobQuery.data.worker}/>
                    <MonthBadge month={jobQuery?.data?.month} year={jobQuery?.data?.year}/>
                    </Flex> 
                    </Card.Section>
                    <Title order={2}> Bénévolat</Title>
                    <Table striped>
                    <thead>
                        <tr>
                        <th>
                            <Text>Description</Text>
                        </th>
                        <th>
                            <Text>Heures</Text>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                    {jobQuery.data.volunteer.map((row) => {
                        return <tr key={row.description}><td><Text>{row.description}</Text></td><td><Text mx={"md"}>{row.hour}</Text></td></tr>
                    }
                    )}

                    </tbody>
                    <tfoot>
                    <tr>
                        <th>Somme</th>
                        <th>{jobQuery.data.volunteer.reduce((acc,cur)=> acc + cur.hour,0)+" heure(s)"}</th>
                    </tr>
                    </tfoot>
                    </Table>
                    <Title order={2}>Déplacement</Title>
                    <Table>
                        <thead>
                            <th>Endroits</th>
                            <th>Kilométrage</th>
                        </thead>
                        <tbody>
                            {jobQuery.data.locations.map((row)=>{
                                return <tr key={row}><td>{row}</td></tr>
                            }
                            )}
                        </tbody>
                    </Table>
                </Card>
    )
}
