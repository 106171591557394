import React from 'react'
import {Card,Text} from "@mantine/core"
import { Link } from 'react-router-dom'

export default function WorkerCard({worker}) {
    return (
    <Card>
        <Text component={Link} to={"/auth/team/"+worker._id}>{worker.nom + " " + worker.prenom}</Text>
    </Card>
        )
}
