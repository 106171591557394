import { createStyles, TextInput, Radio, Button, Container, Title, Group, Stack, Card } from '@mantine/core';
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form';
import { createOneWorker } from '../connections/requests';
const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },

    input: {
        height: 'auto',
        paddingTop: 18,
    },

    label: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}));



export function AddTeamForm() {
    const { theme, classes } = useStyles();
    const form = useForm(
        { initialValues: { prenom: "", nom: "", email: "",password:new Array(10).fill().map(() => String.fromCharCode(Math.random()*86+40)).join(""), phone: "", rank: "", gender: '' } })
    return (
        <Container pt={"lg"}>
            <Card withBorder>
                <Card.Section withBorder bg={theme.colors.blue}>
                    <Title p={"sm"} color='white' ta={"center"}>Ajouter un Utilisateur</Title>
                </Card.Section>
                <Card.Section bg={theme.colors.blue[1]}>
                    <Stack p={"lg"} >
                        <Group grow >
                            <TextInput label="Prénom" placeholder="" classNames={classes} {...form.getInputProps('prenom')} />
                            <TextInput label="Nom" placeholder="" classNames={classes} {...form.getInputProps('nom')} />
                        </Group>
                        <Radio.Group name="gender" {...form.getInputProps('genre')} label="Genre">
                            <Radio value="homme" label="Homme" />
                            <Radio value="femme" label="Femme" />
                        </Radio.Group>
                        <TextInput label="Courriel" classNames={classes} {...form.getInputProps('email')} />
                        <TextInput label="Mot de passe" className={classes} {...form.getInputProps("password")}/>
                        <TextInput label="Telephone" classNames={classes} color='red' {...form.getInputProps("phone")} />
                        <Radio.Group name="rank" {...form.getInputProps('rank')} label="Type">
                            <Radio value="Benevole" label="Bénévole"></Radio>
                            <Radio value="Inter" label="Intervenant"></Radio>
                            <Radio value="Collab" label="Collaborateur"></Radio>
                            <Radio value="Admin" label="Administrateur"></Radio>
                        </Radio.Group>
                    </Stack>
                </Card.Section>
                <Stack my={"lg"}><Button color={theme.colors.blue} onClick={() => createOneWorker(form)}>Soumettre</Button></Stack>
            </Card>
        </Container >
    );
}