import { createStyles, Text, UnstyledButton,Flex } from '@mantine/core'
import { IconUserPlus } from '@tabler/icons-react'
import React from 'react'
import { Link } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 700,
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin:"1rem",
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: 90,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: `${theme.shadows.md} !important`,
            transform: 'scale(1.05)'

        },
    },
}));


const addTeam = {
    ref:"addTeam",
    to:"/auth/form/addTeam",
    color:"green",
    label:"Ajouter un intervenant"
}
const addChrono = {
    ref:"addChrono",
    to:"/auth/form/addChrono",
    color:"green",
    label:"Ajouter un suivi"
}
const addPayment = {
    ref:"addPayment",
    to:"/auth/form/addPayment",
    color:"green",
    label:"Ajouter un paiement"
}
const addJob = {
    ref:"addJob",
    to:"/auth/form/addJob",
    color:"violet",
    label:"Ajouter un bénévolat"
}
const buttonList = [addTeam,addChrono,addPayment,addJob]

export default function ShortcutNav({shortcuts}) {

const list = shortcuts.map(s => buttonList.find( b => b.ref===s) ).filter(b => b?.to)
    return (
        <Flex>
            {
            list.map( b => <LinkButton button={b}/>)
            }
            
        </Flex>
    )
}

function LinkButton({button}){
    const {to,color,label} = button;
    const { classes, theme } = useStyles();
    return(
        <UnstyledButton component={Link} to={to}  className={classes.item} style={{backgroundColor:theme.colors[color][4]}}>
                <IconUserPlus color={theme.dark} size={32} />
                <Text underline={false} size="md" mt={7} mx={"lg"} color={"black"}>
                    {label}
                </Text>
        </UnstyledButton>
    )

}