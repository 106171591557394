import { Navbar, Stack, createStyles } from "@mantine/core";

import NavButton from "./navButton";
const useStyles = createStyles((theme) => ({
    navbar: {

        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.blue[9],
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[2]}`,

    },

    mainSection: {
        paddingBottom: theme.spacing.sm,
    },

    user: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
        borderRadius: theme.radius.sm,
        transition: 'background-color 100ms ease',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },

        [theme.fn.smallerThan('xs')]: {
            display: 'none',
        },
    },

    burger: {
        [theme.fn.largerThan('xs')]: {
            display: 'none',
        },
    },

    userActive: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    tabs: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    tabsList: {
        borderBottom: '0 !important',
    },

    tab: {
        fontWeight: 500,
        height: 38,
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },

        '&[data-active]': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
        },
    },
}));
export function Navigation() {
    const { classes } = useStyles();
    return (
        <Navbar width={{ base: 150 }} height={{ base: 700 }} className={classes.navbar}>
            <Stack spacing={"xs"} align="center">
                <NavButton href="" name="Accueil" />
                <NavButton href="chrono" name="Suivi" />
                <NavButton href="member" name="Membres" />
                <NavButton href="team" name="Équipe" />
                <NavButton href="event" name="Activités" />
                <NavButton href="payment" name="Revenues"/>
                <NavButton href="grant" name="Subventions"/>

                <NavButton href="form" name="Formulaires" />
                
            </Stack>
        </Navbar>);
}
export default Navigation;