import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Paper,
  Group,
  Button,
  Stack,
  Container,
  Title,
  useMantineTheme
} from '@mantine/core';
import { logIn } from '../connections/requests';
import { useNavigate } from 'react-router-dom';
import { getURL } from '../connection';
import { useEffect } from 'react';

async function tryLogin(values,navigate){
 const response = await logIn(values,navigate);
  return response;
}

export default function Login(props) {
 useEffect(
    () => {getURL()},
    () => {return null},
    []
  )
  
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      terms: true
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
    },
  });
  return (
  <div style={{background:theme.colors.papyrus[0],height:'100vh'}} >
    <Container pt={200} pb={300}  >
      <Title ta={"center"} color='cyan' my={"lg"}>DATAide 2.0</Title>
    <Paper radius="md" p="xl" withBorder {...props}>
      <form onSubmit={form.onSubmit((values) => {tryLogin(values,navigate)})}>
        <Stack>
          <TextInput
            required
            label="Courriel"
            placeholder="hello@adsmqam.org"
            value={form.values.email}
            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
            error={form.errors.email && 'Courriel invalide'}
            radius="md"
          />
          <PasswordInput
            required
            label="Mot de passe"
            placeholder="*******"
            value={form.values.password}
            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
            error={form.errors.password && 'Mot de passe trop court'}
            radius="md"
          />
        </Stack>
        <Group justify="space-between" mt="xl">
          <Button type="submit" radius="xl">
            Connexion
          </Button>
        </Group>
      </form>
    </Paper>
    </Container>
  </div>
  );
}